import React, { Suspense, lazy, useContext, useLayoutEffect } from "react";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";

import "./styles/App.scss";
import "./styles/animations.scss";
import "./styles/bootstrap.min.css";
import "./styles/buttons.scss";
import "./styles/container.scss";
import "./styles/index.scss";
import "./styles/positions.scss";
// import "./styles/input.scss";
import "./styles/cards.scss";
import "./styles/checkbox.scss";
import "./styles/images.scss";
import "./styles/modals.scss";
import "./styles/navbar.scss";
import "./styles/select.scss";
import "./styles/text.scss";
import "./styles/videos.scss";

import axios from "axios";

import { Footer } from "./components/footer/footer";
import { Header } from "./components/header/Header";

import { useCookies } from "react-cookie";
import CookieModal from "./components/cookie/CookieModal";
import { LandingArtiste } from "./pages/landing/LandingArtiste";
import { LandingOrganisateur } from "./pages/landing/LandingOrganisateur";

const Collections = lazy(() => import("./pages/collections/Collections"));
// const Product = lazy(() => import("./pages/product/Product"));
const Login = lazy(() => import("./pages/login/Login"));
const Register = lazy(() => import("./pages/register/Register"));
const Profile = lazy(() => import("./pages/profile/Profile"));
const Purchase = lazy(() => import("./pages/purchase/Purchase"));

// const AllComponents = lazy(() =>
//   import("./pages/all-components/AllComponents")
// );
//const AdminAccount = lazy(() => import("./pages/admin-account/AdminAccount"));

// const ProfileAdmin = lazy(() => import("./pages/admin-account/ProfileAdmin"));
// const ResetPassword = lazy(() => import("./pages/admin-account/ResetPassword"));
// const SuiviCommandes = lazy(() =>
//   import("./pages/admin-account/SuiviCommandes")
// );
// const ManageArticles = lazy(() =>
//   import("./pages/admin-account/ManageArticles")
// );

const AdminTabs = lazy(() => import("./pages/admin/AdminTabs"));

import Container from "react-bootstrap/Container";
import Spinner from "react-bootstrap/Spinner";
import { AuthContext } from "./context/AuthContext";
import ChatContext from "./context/ChatContext";
import { ConditionsGeneralesContent } from "./pages/footer/CG/ConditionsGenerales";
import { PolitiqueContent } from "./pages/footer/confidentialite/Confidentialite";
import IFrameArtist from "./pages/profile-artist/iFrameArtist";
const Chat = lazy(() => import("./pages/chat/Chat"));

axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
axios.defaults.baseURL =
  //   "http://localhost:5000/book-now-f328d/europe-west2/api";
  "https://europe-west2-book-now-f328d.cloudfunctions.net/api";

const Wrapper = ({ children }) => {
  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);
  return children;
};

const LoadingPage = () => {
  return (
    <Container
      fluid={+true}
      className="position_center"
      style={{ height: "100vh" }}
    >
      <Spinner variant="primary" animation="border" />
    </Container>
  );
};
function App() {
  // Add all route here
  const { currentDBUser, currentUser } = useContext(AuthContext);
  const [cookies, setCookie] = useCookies(["book-now-cgu"]);
  const [showModalCookie, setShowModalCookie] = React.useState(
    !cookies["book-now-cgu"]
  );

  function loadGTM() {
    const cookiesAccepted = localStorage.getItem("cookiesAccepted");
    if (cookiesAccepted === "true") {
      const script = document.createElement("script");
      script.src = "https://www.googletagmanager.com/gtag/js?id=G-D5XG234ZC6";
      script.async = true;
      document.head.appendChild(script);

      window.dataLayer = window.dataLayer || [];
      function gtag() {
        dataLayer.push(arguments);
      }
      gtag("js", new Date());
      gtag("config", "G-D5XG234ZC6");
    }
  }

  // Appel de la fonction au démarrage de l'application
  loadGTM();

  const routes = [
    {
      path: "/",
      component:
        currentUser.isLoading === false && currentUser.uid !== undefined ? (
          currentDBUser ? (
            currentDBUser.accountType === "artiste" ? (
              <Suspense fallback={<LoadingPage />}>
                <LandingArtiste />
              </Suspense>
            ) : (
              <Suspense fallback={<LoadingPage />}>
                <LandingOrganisateur />
              </Suspense>
            )
          ) : (
            <LoadingPage />
          )
        ) : (
          <Suspense fallback={<LoadingPage />}>
            <LandingOrganisateur />
          </Suspense>
        ),
    },
    {
      path: "/artist",
      component: (
        <Suspense fallback={<LoadingPage />}>
          <LandingArtiste />
        </Suspense>
      ),
    },
    {
      path: "/collections/events",
      component: (
        <Suspense fallback={<LoadingPage />}>
          <Collections type="event" />
        </Suspense>
      ),
    },
    {
      path: "/collections/artists",
      component: (
        <Suspense fallback={<LoadingPage />}>
          <Collections type="user" />
        </Suspense>
      ),
    },
    {
      path: "/login",
      component: (
        <Suspense fallback={<LoadingPage />}>
          <Login />
        </Suspense>
      ),
    },
    {
      path: "/register/:accountTypeProps",
      component: (
        <Suspense fallback={<LoadingPage />}>
          <Register />
        </Suspense>
      ),
    },
    {
      path: "/register",
      component: (
        <Suspense fallback={<LoadingPage />}>
          <Register />
        </Suspense>
      ),
    },
    {
      path: "/cgv",
      component: <ConditionsGeneralesContent />,
    },
    {
      path: "/pdc",
      component: <PolitiqueContent />,
    },
    // {
    //   path: "/all-components",
    //   component: (
    //     <Suspense fallback={<LoadingPage />}>
    //       <AllComponents />
    //     </Suspense>
    //   ),
    // },
    {
      path: "/admin",
      component: (
        <Suspense fallback={<LoadingPage />}>
          <AdminTabs />
        </Suspense>
      ),
    },
    {
      path: "/profile/:uid",
      component: (
        <Suspense fallback={<LoadingPage />}>
          <Profile />
        </Suspense>
      ),
    },
    {
      path: "/profile/iframe/:uid",
      component: (
        <Suspense fallback={<LoadingPage />}>
          <IFrameArtist />
        </Suspense>
      ),
    },
    {
      path: "/purchase",
      component: (
        <Suspense fallback={<LoadingPage />}>
          <Purchase />
        </Suspense>
      ),
    },
    {
      path: "/chat",
      component: (
        <Suspense fallback={<LoadingPage />}>
          <Chat />
        </Suspense>
      ),
    },
    {
      path: "unautorized",
      component: (
        <div>
          <h1>Non autorisé</h1>
          <p>Vous n'avez pas le droit d'aller sur cette page</p>
        </div>
      ),
    },
    {
      path: "*",
      component: (
        <div
          className="position_center flex-column"
          style={{ minHeight: "80vh" }}
        >
          <h1>404</h1>
          <p>Vérifier l'URL</p>
        </div>
      ),
    },
  ];

  React.useEffect(() => {
    console.log("version 0.4.7");
  });

  return (
    <div className="App">
      <BrowserRouter>
        <ChatContext>
          <Header />
          <CookieModal
            showModal={
              showModalCookie &&
              window.location.pathname !== "/cgv" &&
              window.location.pathname !== "/pdc"
            }
            toogleModal={(acceptGA) => {
              setShowModalCookie(false);
              setCookie(
                "book-now-cgu",
                { accptCGU: true, acceptGA },
                { path: "/", maxAge: 30 * 24 * 60 * 60 }
              );
            }}
          />
          <Wrapper>
            <Routes>
              <React.Fragment>
                {routes.map((route, i) => (
                  <Route
                    key={"route_" + i}
                    path={route.path}
                    element={route.component}
                  />
                ))}
              </React.Fragment>
            </Routes>
          </Wrapper>
        </ChatContext>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
