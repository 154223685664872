import { faChevronDown } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Stack from "react-bootstrap/Stack";

import Offcanvas from "react-bootstrap/Offcanvas";

import data from "../../data/typeArtiste.json";

import { useNavigate } from "react-router-dom";
const AssistanceRessources = ({
  showAssistance,
  setShowAssistance,
  ...props
}) => {
  const navigate = useNavigate();
  const VILLE = [
    "Paris",
    "Marseille",
    "Toulouse",
    "Bordeaux",
    "Nice",
    "Nantes",
    "Lille",
    "Strasbourg",
  ];
  return (
    <>
      <Offcanvas
        backdropClassName="transparent"
        placement="bottom"
        show={showAssistance}
        onHide={() => setShowAssistance(false)}
        {...props}
        className="container-black h-auto"
      >
        <Offcanvas.Header>
          <Row className="position_center w-100 m-0 p-0">
            <button
              className="btn-empty light-grey position_center fa-2x hover-primary"
              onClick={() => setShowAssistance(false)}
            >
              <FontAwesomeIcon icon={faChevronDown} />
            </button>
          </Row>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Row
            className="d-flex justify-content-center align-items-start px-5"
            style={{ height: "260px" }}
          >
            <p className="yellow position_center"> Type d'Artistes</p>

            {[
              ...Array(Math.ceil(Object.keys(data.typeArtist).length / 1)).fill(
                1
              ),
            ].map((value, index) => (
              <Col
                key={"event_name_content_" + value + index}
                className="px-0 mx-0"
              >
                {Object.keys(data.typeArtist)
                  .slice(index * 1, index * 1 + 1)
                  .map((artistType, key) => (
                    <Stack
                      key={artistType + key}
                      className="text-center white position_center"
                      direction="vertical"
                      gap={1}
                    >
                      <p>
                        {artistType.replaceAll("(ou Cirque)", "")} par ville
                      </p>
                      {VILLE.map((city, k) => (
                        <Button
                          key={"event_name_" + k}
                          variant="empty"
                          className="btn-link w-80 white p-0"
                          onClick={() => {
                            navigate("/collections/artists", {
                              state: {
                                typeArtistParam: artistType,
                                cityParam: city,
                              },
                            });
                            setShowAssistance(false);
                          }}
                        >
                          {artistType.replaceAll("(ou Cirque)", "")} {city}
                        </Button>
                      ))}
                      <Button
                        variant="empty"
                        className="btn-link w-80 white p-0"
                        onClick={() => {
                          navigate("/collections/artists", {
                            state: {
                              typeArtistParam: artistType,
                            },
                          });
                          setShowAssistance(false);
                        }}
                      >
                        Toutes les villes
                      </Button>
                    </Stack>
                  ))}
              </Col>
            ))}
          </Row>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default AssistanceRessources;
