import { createContext, useEffect, useState } from "react";
import { auth } from "../firestore";
import { onAuthStateChanged } from "firebase/auth";
import axios from "axios";
export const AuthContext = createContext({
  currentUser: { isLoading: true },
  currentDBUser: null,
  isAdmin: null,
});

export default ({ children }) => {
  const [currentUser, setCurrentUser] = useState({ isLoading: true });
  const [currentDBUser, setCurrentDBUser] = useState(null);
  const [isAdmin, setIsAdmin] = useState(null);

  useEffect(() => {
    const unsub = onAuthStateChanged(auth, (user) => {
      if (user) {
        setCurrentUser({ ...user, isLoading: false });
        axios.get(`/user/${user.uid}`).then((response) => {
          setCurrentDBUser(response.data.data);
          setIsAdmin(response.data.data.admin);
        });
      } else {
        setCurrentUser({
          isLoading: false,
        });
        setCurrentDBUser(null);
      }
    });
    return unsub;
  }, []);

  const updateDBUser = () => {
    axios.get(`/user/${currentUser.uid}`).then((response) => {
      setCurrentDBUser(response.data.data);
    });
  };

  return (
    <AuthContext.Provider
      value={{ currentUser, currentDBUser, updateDBUser, isAdmin }}
    >
      {children}
    </AuthContext.Provider>
  );
};
