import React, { useContext } from "react";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import iFrame from "../../assets/images/iframe-book-now.webp";
import { AuthContext } from "../../context/AuthContext";
const IFrameArtist = () => {
  const { currentUser } = useContext(AuthContext);

  return (
    <Container style={{ minHeight: "100vh" }}>
      <Row className="mb-3 flex-nowrap text-start">
        <IFrameArtistButton uid={currentUser.uid} />
      </Row>
    </Container>
  );
};

export const IFrameArtistButton = ({ uid }) => {
  return (
    <a
      href={"https://book-now.fr/profile/" + uid}
      target="_blank"
      rel="noopener noreferrer"
      style={{ position: "absolute", top: "0px", zIndex: 50, left: 0 }}
    >
      <img src={iFrame} style={{ width: "200px" }} />
    </a>
  );
};
export default IFrameArtist;
