import React from "react";

import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
const CookieModal = ({ showModal, toogleModal }) => {
  const handleAcceptCookies = () => {
    localStorage.setItem("cookiesAccepted", "true");
    toogleModal(true);
  };

  return (
    <Modal bottom show={showModal} size="xl" onHide={() => toogleModal(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Accepter les Cookies</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className="light">
          BOOK’NOW utilise des cookies pour améliorer l’expérience utilisateur,
          garantir le bon fonctionnement du site et optimiser ses performances
          techniques. Ces raisons peuvent nous amener à partager des données
          concernant votre utilisation du site avec des sociétés prestataires.
          En cliquant sur « Accepter », vous consentez à l’utilisation de
          cookies sur le site, conformément à la description figurant dans les
          politiques de confidentialité.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button
          size="sm"
          variant="primary"
          onClick={() => {
            handleAcceptCookies();
            toogleModal(true);
          }}
        >
          Accepter
        </Button>
        <Button
          size="sm"
          variant="empty"
          className="hover-orange"
          onClick={() => toogleModal(false)}
        >
          Refuser
        </Button>
        <Link
          to="pdc"
          target="_blank"
          type="button"
          className="hover-orange btn-sm btn-empty"
        >
          En savoir plus
        </Link>
      </Modal.Footer>
    </Modal>
  );
};
export default CookieModal;
