import React from "react";

import ToastContainer from "react-bootstrap/ToastContainer";
import Toast from "react-bootstrap/Toast";
const Notification = ({
  showToast,
  setShowToast,
  autohide = true,
  titre = "",
  variant = "primary",
  className = "",
}) => {
  return (
    <ToastContainer
      style={{
        position: "fixed",
        marginTop: "100px",
        left: "40%",
        top: "0",
        zIndex: 1000000,
      }}
      className={className}
    >
      <Toast
        autohide={autohide}
        show={showToast}
        onClose={() => setShowToast(false)}
        bg={variant.toLowerCase()}
      >
        <Toast.Header>
          <p className="w-100 text-justify">{titre}</p>
        </Toast.Header>
      </Toast>
    </ToastContainer>
  );
};
export default Notification;
