import React, { useState, useContext, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

// Component
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";

import { AuthContext } from "../../context/AuthContext";
import AdressSelect from "../../components/AdressSelect/AdressSelect";
// Pictures
// TODO: To change to marketplace logo
import axios from "axios";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUserMusic,
  faFileLines,
  faMusic,
  faUserCheck,
  faWandMagicSparkles,
  faCalendarCheck,
  faUsers,
} from "@fortawesome/pro-light-svg-icons";

import data from "../../data/typeArtiste.json";
import { Spinner } from "react-bootstrap";

export const LandingOrganisateur = () => {
  const { currentUser } = useContext(AuthContext);
  const typeArtiste1 = Object.keys(data.typeArtist);
  const navigate = useNavigate();
  const [what, setWhat] = useState("");
  const [when, setWhen] = useState(undefined);
  const [where, setWhere] = useState("");

  const [loadingArtistsHighlights, setLoadingArtistsHighlights] =
    useState(true);
  const [artistes, setArtistes] = useState([]);

  useEffect(() => {
    setLoadingArtistsHighlights(true);
    const fetchArtistesEnSurbrillance = async () => {
      try {
        const response = await axios.get("/settings/artists-highlights");
        setArtistes(response.data);
        setLoadingArtistsHighlights(false);
      } catch (error) {
        console.error(
          "Erreur lors de la récupération des artistes en surbrillance :",
          error
        );
      }
    };

    fetchArtistesEnSurbrillance();
  }, []);

  const classNameCardLandingArtist = (index) => {
    if (index === 0) return "grow img-box-shadow-blue border-rounded";
    else if (index === 1) return "grow img-box-shadow-yellow border-rounded";
    else if (index === 2) return "grow img-box-shadow-red border-rounded";
  };

  return (
    <Container className="px-5">
      <Container
        className="px-5 position_center"
        style={{ height: "90vh", flexDirection: "column" }}
      >
        <Row className="position_center ">
          <h1 className=" grow fade-in title black mb-5">
            Trouvez votre artiste
          </h1>
        </Row>
        <Row className="w-100 mt-2">
          <Col className="position_center px-1 w-100">
            <Form className="w-100 mb-4">
              <Form.Group>
                <Form.Label>Que cherchez-vous ?</Form.Label>
                <Row>
                  <InputGroup.Text style={{ width: "0.25rem " }}>
                    <FontAwesomeIcon icon={faUserMusic} />
                  </InputGroup.Text>

                  <Form.Select
                    value={what}
                    onChange={(e) => setWhat(e.target.value)}
                    className="pr-5"
                    aria-label="Default select example"
                    style={{ width: "85%", paddingLeft: "50px" }}
                  >
                    <option value="">Tous types d'artistes</option>
                    {typeArtiste1.map((type) => (
                      <option value={type} key={type}>
                        {type}
                      </option>
                    ))}
                  </Form.Select>
                </Row>
              </Form.Group>
            </Form>
          </Col>
          <Col className="position_center px-1" sm={12} md={4}>
            <Form className="mb-4">
              <Form.Label htmlFor="exampleColorInput">Quelle date ?</Form.Label>
              <Form.Control
                type="date"
                className="px-3"
                value={when}
                onChange={(e) => setWhen(e.target.value)}
              />
            </Form>
          </Col>
          <Col className="position_center px-1" md={4}>
            <Form.Group className="mb-4">
              <Form.Label>Où ?</Form.Label>
              <AdressSelect
                isCity={true}
                placeholder="Saisir votre ville"
                style={{ width: 300 }}
                onBlur={setWhere}
              />
            </Form.Group>
          </Col>
          <Col className="position_center px-1" sm={12}>
            <Button
              size="md"
              variant="primary"
              className="position_center"
              onClick={() => {
                navigate("/collections/artists", {
                  state: { what, when, where },
                });
              }}
            >
              Rechercher
            </Button>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row>
          <h3 className="text-start mb-4">Les artistes à découvrir</h3>
          {loadingArtistsHighlights ? (
            <Spinner animation="border" variant="primary" role="status" />
          ) : (
            <>
              {[...Array(3).fill(1)].map((value, index) => (
                <Col md={4} xs={12} className="grow" key={index}>
                  <Button
                    variant="empty"
                    onClick={() => navigate(`/profile/${artistes[index].link}`)}
                  >
                    <div style={{ width: "350px", height: "250px" }}>
                      <img
                        width="350px"
                        height="250px"
                        src={artistes[index].src}
                        style={{ objectFit: "cover", width: "100%" }}
                        className={classNameCardLandingArtist(index)}
                      />
                    </div>
                  </Button>
                  <h2
                    className="white lobster text-end mr-5"
                    style={{
                      position: "relative",
                      top: "-50px",
                      left: "-20px",
                    }}
                  >
                    {artistes[index].title}
                  </h2>
                </Col>
              ))}
            </>
          )}
        </Row>
      </Container>
      <Container className="section-rounded mb-5 pb-5">
        <Row>
          <h2> Avec Book'Now</h2>
        </Row>

        <Row>
          <Col className="position_center flex-column my-2" md={4}>
            <FontAwesomeIcon icon={faMusic} className="fa-5x my-4" />
            <h5>Découvrez les artistes</h5>
            <p>
              Recherchez parmi plus de 1000 Prestataires
              <br /> sélectionnés par notre équipe.
            </p>
          </Col>

          <Col className="position_center flex-column my-2" md={4}>
            <FontAwesomeIcon icon={faUserCheck} className="fa-5x my-4" />
            <h5>Réservez en toute sécurité</h5>
            <p>
              Notre processus de réservation est centralisé, <br /> rapide et
              100% sécurisé.
            </p>
          </Col>
          <Col className="position_center flex-column my-2" md={4}>
            <span className="position_center">
              <FontAwesomeIcon
                icon={faWandMagicSparkles}
                className="fa-5x my-4"
              />
            </span>
            <h5>Aucune paperasse</h5>
            <p>
              Libérez-vous de toutes les démarches administratives :
              <br /> paiement et déclarations des artistes.
            </p>
          </Col>
          <Col className="position_center flex-column my-2" md={4}>
            <FontAwesomeIcon icon={faFileLines} className="fa-5x my-4" />
            <h5>Recevez votre devis personnalisé</h5>
            <p>
              Soumettez une demande de devis et
              <br /> recevez plusieurs devis personnalisés.
            </p>
          </Col>
          <Col className="position_center flex-column my-2" md={4}>
            <FontAwesomeIcon icon={faCalendarCheck} className="fa-5x my-4" />
            <h5>Créez votre évènement</h5>
            <p>
              Décrivez votre évènement et
              <br /> sélectionnez le meilleur candidat.
            </p>
          </Col>
          <Col className="position_center flex-column my-2" md={4}>
            <FontAwesomeIcon icon={faUsers} className="fa-5x my-4" />
            <h5>Une équipe de passionné</h5>
            <p>
              Une équipe disponible 7j/7 pour vous
              <br /> accompagner à trouver l'artiste idéal.
            </p>
          </Col>
        </Row>
      </Container>

      <section className="background-white">
        {/* <Container className="my-3 position_center">
          <Link to="/register">
            <Button
              size="lg"
              variant="primary"
              className="yellow position_center my-4"
            >
              <h2 className="mx-3 mt-2 white">S'INSCRIRE </h2>
              <img
                src={arrowLeft}
                className="icon-svg-sm ml-5"
                alt="flèche gauche"
              />
            </Button>
          </Link>
        </Container> */}
        {/* <Container>
          <Row className="text-start mx-5">
            <h2>Les artistes à découvrir</h2>
          </Row>
          <Row>
            <Col>
              <Button
                variant="empty"
                onClick={() => handleClick("Articles religieux")}
                className="position_center grow my-3"
                style={{ flexDirection: "column" }}
              >
                <img
                  src={Chapelet}
                  className="img-background"
                  alt="articles religieux collection"
                />
                <h1 className="white absolute">
                  Articles <br />
                  religieux
                </h1>
              </Button>
            </Col>{" "}
          </Row>
        </Container> */}
        <Container className="my-5">
          {/* <Fade bottom> */}
          <Row>
            <h2>Élaborer un devis</h2>
            <h4>
              Contactez-nous et un conseiller vous répondra le plus rapidement
              possible
            </h4>
            <Link
              to={currentUser.uid ? `/profile/${currentUser.uid}` : "/login"}
              state={{ open: true }}
              className="position_center"
            >
              <Button
                size="lg"
                variant="primary"
                className="yellow position_center my-4"
              >
                <h2 className="mx-2 mt-2 white">Devis sur mesure</h2>
              </Button>
            </Link>
          </Row>
          {/* </Fade> */}
        </Container>
      </section>
    </Container>
  );
};
