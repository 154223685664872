import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";

import { signOut } from "firebase/auth";
import { auth } from "../../firestore";

import { AuthContext } from "../../context/AuthContext";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRightFromBracket,
  faEnvelope,
  faMessages,
  faPhone,
} from "@fortawesome/pro-light-svg-icons";

import { ChatContext } from "../../context/ChatContext";
import {
  setDoc,
  doc,
  updateDoc,
  serverTimestamp,
  getDoc,
  onSnapshot,
} from "firebase/firestore";
import { db } from "../../firestore";

import Logo from "../../assets/images/logo-horizontal.png";
import "../../styles/navbar.scss";
import { NavDropdown } from "react-bootstrap";
import { faXmark } from "@fortawesome/pro-solid-svg-icons";

const DisplayName = ({ displayName, name, surname, email }) => {
  if (displayName) {
    return <p className="black my-0 mr-2">{displayName}</p>;
  } else if (name && surname) {
    return <p className="black my-0 mr-2">{name + " " + surname}</p>;
  } else {
    return <p className="black my-0 mr-2">{email}</p>;
  }
};
export const Header = () => {
  const navigate = useNavigate();
  const [expanded, setExpanded] = useState(false);
  const { currentUser, currentDBUser } = useContext(AuthContext);
  const { dispatch } = useContext(ChatContext);

  const [notRead, setNotRead] = useState(0);

  useEffect(() => {
    let unSub = undefined;
    if (currentUser.uid) {
      unSub = onSnapshot(doc(db, "userChats", currentUser.uid), (doc) => {
        const docData = doc.data();
        if (docData) {
          const sortedData = Object.entries(docData).reduce(
            (acc, val) => (acc += val[1]?.lastMessage?.notRead || 0),
            0
          );
          setNotRead(sortedData);
        }
      });
    }
    return () => {
      unSub && unSub();
    };
  }, [currentUser.uid]);

  const handleContact = async () => {
    if (currentUser.uid == undefined) {
      navigate("/login");
      return;
    }
    const combinedId =
      currentUser.uid > "UUIYpK7n2fMENWO8q9z5eEfaN5H2"
        ? currentUser.uid + "UUIYpK7n2fMENWO8q9z5eEfaN5H2"
        : "UUIYpK7n2fMENWO8q9z5eEfaN5H2" + currentUser.uid;
    const data = {
      uid: "UUIYpK7n2fMENWO8q9z5eEfaN5H2",
      displayName: "wilfried",
      email: "wilfried@book-now.fr",
      photoURL:
        "https://firebasestorage.googleapis.com/v0/b/book-now-f328d.appspot.com/o/blank-profile-picture-973460_640.png?alt=media",
    };
    try {
      const res = await getDoc(doc(db, "chats", combinedId));
      if (!res.exists()) {
        //create a chat in chats collection
        await setDoc(doc(db, "chats", combinedId), { messages: [] });
      }
      //update user chats
      await updateDoc(doc(db, "userChats", currentUser.uid), {
        [combinedId]: {
          userInfo: {
            uid: data.uid,
            displayName: data.displayName || data.email,
            photoURL: data.photoURL,
          },
          date: serverTimestamp(),
        },
      });
    } catch (err) {
      console.log("err", err);
    }
    dispatch({ type: "CHANGE_USER", payload: data });
    navigate("/chat");
  };

  const getComponent = () => {
    if (!currentDBUser) {
      return (
        <>
          <NavDropdown
            title="Besoin d'aide ?"
            id="basic-nav-dropdown"
            className="black"
          >
            <NavDropdown.Item style={{ pointerEvents: "none" }}>
              <FontAwesomeIcon icon={faPhone} className="mr-3" />
              06 40 12 95 24
            </NavDropdown.Item>
            <NavDropdown.Item
              target="_blank"
              rel="noopener noreferrer"
              href="mailto:contact@book-now.fr"
            >
              <FontAwesomeIcon icon={faEnvelope} className="mr-3" />
              contact@book-now.fr
            </NavDropdown.Item>
            <NavDropdown.Item onClick={handleContact}>
              <FontAwesomeIcon icon={faMessages} className="mr-3" />
              Chattez avec nous
            </NavDropdown.Item>
          </NavDropdown>
          <Nav.Link
            onClick={() => {
              setExpanded(false);
            }}
            href="/register/artiste"
            className="d-flex flex-row"
            aria-label="Panier"
          >
            <p className="black ml-2 my-0">Je suis un artiste</p>
          </Nav.Link>
          <Nav.Link
            onClick={() => {
              setExpanded(false);
            }}
            href="/register/organisateur"
            className="d-flex flex-row"
            aria-label="Panier"
          >
            <p className="black ml-2 my-0">Je suis un organisateur</p>
          </Nav.Link>
          <Nav.Link
            onClick={() => {
              setExpanded(false);
            }}
            href="/collections/artists"
            className="d-flex flex-row position_center"
            aria-label="Page profile"
          >
            <Button
              size="xs"
              variant="outline-primary"
              className="position_center"
            >
              Catalogue des artistes
            </Button>
          </Nav.Link>
        </>
      );
    }
    if (currentDBUser.admin) {
      return (
        <>
          <NavDropdown
            title="Besoin d'aide ?"
            id="basic-nav-dropdown"
            className="black"
          >
            <NavDropdown.Item style={{ pointerEvents: "none" }}>
              <FontAwesomeIcon icon={faPhone} className="mr-3" />
              06 40 12 95 24
            </NavDropdown.Item>
            <NavDropdown.Item
              target="_blank"
              rel="noopener noreferrer"
              href="mailto:contact@book-now.fr"
            >
              <FontAwesomeIcon icon={faEnvelope} className="mr-3" />
              contact@book-now.fr
            </NavDropdown.Item>
            <NavDropdown.Item onClick={handleContact}>
              <FontAwesomeIcon icon={faMessages} className="mr-3" />
              Chattez avec nous
            </NavDropdown.Item>
          </NavDropdown>
          <Nav.Link
            onClick={() => {
              setExpanded(false);
            }}
            href="/collections/events"
            className="d-flex flex-row position_center"
            aria-label="Page profile"
          >
            <Button
              size="xs"
              variant="outline-primary"
              className="position_center"
            >
              Trouver un événement
            </Button>
          </Nav.Link>
          <Nav.Link
            onClick={() => {
              setExpanded(false);
            }}
            href="/collections/artists"
            className="d-flex flex-row position_center"
            aria-label="Page profile"
          >
            <Button
              size="xs"
              variant="outline-primary"
              className="position_center"
            >
              Catalogue des artistes
            </Button>
          </Nav.Link>
          <Nav.Link
            onClick={() => {
              setExpanded(false);
            }}
            href="/chat"
            className="d-flex flex-row"
            aria-label="Panier"
          >
            <div style={{ marginRight: 5, fontWeight: "bold" }}>
              {notRead > 0 && notRead}
            </div>
            <FontAwesomeIcon icon={faMessages} size="lg" />
          </Nav.Link>
        </>
      );
    }
    if (currentDBUser.accountType === "artiste") {
      return (
        <>
          <NavDropdown
            title="Besoin d'aide ?"
            id="basic-nav-dropdown"
            className="black"
          >
            <NavDropdown.Item style={{ pointerEvents: "none" }}>
              <FontAwesomeIcon icon={faPhone} className="mr-3" />
              06 40 12 95 24
            </NavDropdown.Item>
            <NavDropdown.Item
              target="_blank"
              rel="noopener noreferrer"
              href="mailto:contact@book-now.fr"
            >
              <FontAwesomeIcon icon={faEnvelope} className="mr-3" />
              contact@book-now.fr
            </NavDropdown.Item>
            <NavDropdown.Item onClick={handleContact}>
              <FontAwesomeIcon icon={faMessages} className="mr-3" />
              Chattez avec nous
            </NavDropdown.Item>
          </NavDropdown>
          <Nav.Link
            onClick={() => {
              setExpanded(false);
            }}
            href="/collections/events"
            className="d-flex flex-row position_center"
            aria-label="Page profile"
          >
            <Button
              size="xs"
              variant="outline-primary"
              className="position_center"
            >
              Trouver un événement
            </Button>
          </Nav.Link>
          <Nav.Link
            onClick={() => {
              setExpanded(false);
            }}
            href="/chat"
            className="d-flex flex-row"
            aria-label="Panier"
          >
            <div style={{ marginRight: 5, fontWeight: "bold" }}>
              {notRead > 0 && notRead}
            </div>
            <FontAwesomeIcon icon={faMessages} size="lg" />
          </Nav.Link>
        </>
      );
    } else {
      return (
        <>
          <NavDropdown
            title="Besoin d'aide ?"
            id="basic-nav-dropdown"
            className="black"
          >
            <NavDropdown.Item style={{ pointerEvents: "none" }}>
              <FontAwesomeIcon icon={faPhone} className="mr-3" />
              06 40 12 95 24
            </NavDropdown.Item>
            <NavDropdown.Item
              target="_blank"
              rel="noopener noreferrer"
              href="mailto:contact@book-now.fr"
            >
              <FontAwesomeIcon icon={faEnvelope} className="mr-3" />
              contact@book-now.fr
            </NavDropdown.Item>
            <NavDropdown.Item onClick={handleContact}>
              <FontAwesomeIcon icon={faMessages} className="mr-3" />
              Chattez avec nous
            </NavDropdown.Item>
          </NavDropdown>
          <Nav.Link
            onClick={() => {
              setExpanded(false);
            }}
            href="/collections/artists"
            className="d-flex flex-row position_center"
            aria-label="Page profile"
          >
            <Button
              size="xs"
              variant="outline-primary"
              className="position_center"
            >
              Catalogue des artistes
            </Button>
          </Nav.Link>
          <Nav.Link
            onClick={() => {
              setExpanded(false);
            }}
            className="d-flex flex-row"
            href="/chat"
            aria-label="Panier"
          >
            <div style={{ marginRight: 5, fontWeight: "bold" }}>
              {notRead > 0 && notRead}
            </div>
            <FontAwesomeIcon icon={faMessages} size="lg" />
          </Nav.Link>
        </>
      );
    }
  };

  return (
    <Navbar bg="white" expand="lg" className="mx-5 mt-2" expanded={expanded}>
      <Container fluid={+true} className="d-flex">
        <Navbar.Brand className="flex-row d-flex">
          <div
            className="btn-empty grow"
            onClick={() => {
              navigate("/");
              setExpanded(false);
            }}
          >
            <img
              src={Logo}
              style={{ cursor: "pointer" }}
              width="200"
              // height="30"
              className="d-inline-block align-top grow"
            />
          </div>
        </Navbar.Brand>

        <Navbar.Toggle
          aria-controls="navbarScroll"
          onClick={() => setExpanded(expanded ? false : "expanded")}
        />
        <Navbar.Collapse id="navbarScroll">
          <Nav
            className={`mr-2 my-2 my-lg-0 position_center `}
            style={{ maxHeight: "300px" }}
            navbarScroll
          >
            {expanded && (
              <Button
                variant="empty hover-primary"
                onClick={() => setExpanded(false)}
                className="w-100 d-flex justify-content-end"
              >
                <FontAwesomeIcon icon={faXmark} size="xl" />
              </Button>
            )}
            {currentUser.isLoading === false && getComponent()}
            {currentUser.isLoading === false &&
            currentUser.uid !== undefined ? (
              <Nav.Link
                onClick={() => {
                  setExpanded(false);
                }}
                href={`/profile/${currentUser.uid}`}
                className="d-flex flex-row position_center"
                aria-label="Page profile"
              >
                <img
                  src={currentDBUser?.photoURL}
                  className="profile-xs mr-1"
                />
                <p className="black my-0 mr-2">
                  {currentDBUser
                    ? currentUser.displayName ||
                      currentDBUser?.name ||
                      "Profil Anonyme"
                    : "Chargement..."}
                </p>
              </Nav.Link>
            ) : null}
            {currentUser.isLoading === false &&
            currentUser.uid !== undefined ? (
              <>
                <Nav.Link
                  onClick={() => {
                    setExpanded(false);
                    signOut(auth);
                  }}
                  href="/"
                  className="d-flex flex-row position_center"
                  aria-label="Page profile"
                >
                  <p className="black my-0 mr-2">
                    <FontAwesomeIcon
                      icon={faArrowRightFromBracket}
                      className="grow hover-orange fa-x ml-2"
                    />
                  </p>
                </Nav.Link>
              </>
            ) : (
              <Nav.Link
                onClick={() => {
                  setExpanded(false);
                }}
                href="/login"
                className="d-flex flex-row position_center"
                aria-label="Page profile"
              >
                <p className="black my-0 mr-2"> Se connecter</p>
              </Nav.Link>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};
