import React from "react";

import Modal from "react-bootstrap/Modal";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import MangoPay from "../../../assets/icons/mangopay.svg";
// TODO
export const ConditionsGeneralesContent = () => {
  return (
    <Container className="light text-start px-5">
      <Row className="my-5">
        <div className="position_center">
          <img src={MangoPay} style={{ objectFit: "cover", width: "400px" }} />
        </div>
        <p>
          Consultez les conditions générales d'utilisation de Mongopay{" "}
          <a
            href={
              "https://firebasestorage.googleapis.com/v0/b/school-upgreat-pass.appspot.com/o/cgv-mongo%2FPSP_MANGOPAY_FR.pdf?alt=media"
            }
            target="_blank"
            rel="noopener noreferrer"
            className="btn-link w-auto bold"
          >
            ici
          </a>
          .
        </p>
        <p className="py-2">
          La société BOOK’NOW (ci-après « BOOK’NOW ») est titulaire du site
          internet www.book-now.fr (ci-après « le Site internet ») par lequel
          elle met en relation des professionnels de l’évènementiel ou des
          particuliers (ci-après « l’Organisateur ») avec des artistes (ci-après
          « l’Artiste »). <br /> <br />
          L’Organisateur et l’Artiste ainsi que toute autre personne utilisant
          le Site Internet, sont considérés comme des utilisateurs (ci-après «
          les Utilisateurs ») du Site internet. <br /> <br />
          Les présentes conditions générales (ci-après « les CG ») ont pour but
          de définir les termes et conditions de la relation entre BOOK’NOW et
          l’ensemble des Utilisateurs du Site internet. <br /> <br />
          Elles pourront le cas échéant être complétées par d’autres conditions
          particulières. En cas de contradiction entre les CG et ces conditions
          particulières, ces dernières prévalent sur les CG. <br /> <br />
          L’accès et l’utilisation du Site internet par les Utilisateurs,
          impliquent l’acceptation totale et sans réserve des CG et des
          éventuelles autres conditions particulières (ci-après, toutes
          intégrées dans les « CG »). <br /> <br />
          Ces CG pourront éventuellement faire l’objet de modifications par
          BOOK’NOW, sans préavis. Toutefois les CG applicables à l’Artiste ou à
          l’Organisateur seront celles en vigueur lors de la création de leur
          compte sur le Site internet. <br /> <br />
          BOOK’NOW se réserve la faculté, sans préavis et à tout moment, de
          refuser l’accès au Site internet aux Utilisateurs qui enfreignent les
          CG. <br /> <br />
          L’Utilisateur reconnaît qu’il est impossible pour BOOK’NOW de garantir
          la disponibilité du Site internet qui pourra faire l’objet de mises à
          jour ou de bugs informatiques. BOOK’NOW s’efforcera néanmoins à
          garantir cette disponibilité de la manière la plus constante possible.{" "}
        </p>
      </Row>

      <Row className="my-5">
        <h5 className="bold my-2">Article 1 – Objet</h5>
        <p className="py-2">
          1.1 Les présentes CG régissent l’utilisation du Site internet, par
          l’ensemble des Utilisateurs et plus spécifiquement par l’Organisateur
          et l’Artiste.
          <br /> <br /> 1.2 Le Site internet permet : <br /> <br />- A
          l’Organisateur d’avoir accès à une base de données mettant en avant
          des profils d’Artistes, aux fins de faire appel à leurs services dans
          le cadre de l’organisation d’un évènement. A cet effet, BOOK’NOW
          réalise l’ensemble des démarches administratives nécessaires à
          l’utilisation des services de l’Artistes pour les besoins de
          l’évènement. <br /> <br />- Aux Artistes d’être mis en avant et
          contactés lors de l’organisation d’un évènement, selon une
          rémunération garantie. Ils peuvent également bénéficier d’une
          visibilité par BOOK’NOW au titre de ses services dits de production.{" "}
          <br /> <br />
          1.3 Les informations fournies par l’Organisateur ou l’Artiste ne
          portent que sur les données et/ou informations transmises à BOOK’NOW
          lors de l’inscription sur le Site internet, dès lors qu’elles
          n’enfreignent pas la loi ou les CG. Toute information illicite ou
          contraire aux présentes CG pourra être automatiquement supprimée par
          BOOK’NOW.{" "}
        </p>
      </Row>
      <Row className="my-5">
        <h5 className="bold my-2">Article 2 – Obligation des Utilisateurs</h5>
        <p className="py-2">
          2.1 Les Utilisateurs s’engagent à utiliser le Site internet de bonne
          foi, en se conformant aux obligations légales et règlementaires en
          vigueur ainsi qu’aux présentes CG ou conditions particulières.
          BOOK’NOW se réserve la faculté le cas échéant, sans préavis et à sa
          discrétion, de supprimer tout contenu contraire à la législation en
          vigueur, susceptible d’enfreindre les droits d’autrui, jugé
          inapproprié ou qui ne répond pas aux standards de qualité requis.
          BOOK’NOW aura également la faculté de supprimer le compte de tout
          Utilisateur qui ferait l’objet de réclamations réitérées de la part
          d’autres Utilisateurs. <br /> <br />
          2.2 Les Utilisateurs et notamment les Organisateurs et les Artistes
          s’engagent à être de bonne foi et de se conformer aux exigences
          légales, fiscales et règlementaires, en leur qualité de travailleur
          indépendant ou de personne morale. La responsabilité de BOOK’NOW ne
          pourra pas être recherchée si ces derniers ne se sont pas soumis à ces
          obligations. Dans ce contexte, BOOK’NOW aura la faculté de solliciter
          tout document jugé nécessaire, justifiant de la parfaite conformité à
          ces obligations. Elle se réserve la possibilité d’interdire la
          présence sur le Site internet de tout Utilisateur qui ne s’y
          conformerait pas et/ou qui dissimuleraient sciemment de telles
          justificatifs et/ou qui ne se respecterait pas les exigences
          précitées. <br /> <br />
          2.3 Les Utilisateurs n’ont aucune obligation d’inscription pour
          accéder au Site internet et visualiser les pages de présentation des
          Artistes. Toutefois, BOOK’NOW conditionne l’accès à certains services
          – notamment pour publier une annonce ou réserver une mission – et/ou
          certaines pages à une inscription préalable, par le biais d’un
          formulaire d’enregistrement et l’ouverture d’un compte Organisateur ou
          Artiste. A cet effet, l’Organisateur ou l’Artiste devront choisir un
          identifiant et un mot de passe qu’ils s’engagent à conserver et à
          utiliser avec la plus grande vigilance. Pour toute inscription, les
          Utilisateurs devront être âgés d’au moins 18 ans et être capables
          juridiquement de contracter et d’utiliser les services fournis par
          BOOK’NOW.
          <br /> <br /> 2.4 S’agissant du mot de passe, celui-ci est personnel
          et ne peut être cédé à un tiers de sorte qu’il doit rester strictement
          confidentiel. La responsabilité de BOOK’NOW ne pourra pas être engagée
          sur ce point. Dans l’hypothèse où l’Utilisateur soupçonne une
          utilisation de son mot de passe, il devra en informer immédiatement
          BOOK’NOW qui lui proposera de le modifier. En cas d’oubli, l’Artiste
          ou l’Organisateur pourra directement solliciter un nouveau mot de
          passe en cliquant sur « mot de passe oublié ». <br /> <br />
          2.5 S’agissant des informations fournies lors de l’inscription, elles
          devront être exactes et justifiables si besoin. L’Utilisateur s’engage
          à les mettre régulièrement à jour et la responsabilité de BOOK’NOW ne
          pourra pas être engagée pour toute erreur/non actualisation de ces
          informations.
          <br /> <br /> 2.6 S’agissant du contenu transmis, l’Utilisateur
          s’engage à respecter les lois applicables et les droits des tiers.
          BOOK’NOW rappelle que toute exploitation de contenus sans autorisation
          et protégés par des droits quels qu’ils soient peuvent être pénalement
          et civilement répréhensibles. En particulier, l’Utilisateur aura
          interdiction d’utiliser des contenus injurieux ou calomnieux,
          pornographiques ou enfreignant les lois sur la protection des mineurs,
          de déranger les autres Utilisateurs ou de porter atteinte à leurs
          données personnelles par le biais de spams, d’utiliser des contenus
          légalement protégés, notamment par des droits de propriété
          intellectuelle, de faire de la publicité ou de
          commercialiser/distribuer des produits/services légalement protégés ou
          illicites, d’inciter les Utilisateurs à des actions contraires à la
          libre concurrence et plus généralement aux règlements et aux lois.
          BOOK’NOW ne pourra pas être tenu responsable de tout contenu non
          conforme aux présentes CG, diffusé par les Utilisateurs et n’entrera
          dans aucun conflit entre ces derniers, susceptible de gêner la
          prestation de ses services. BOOK’NOW s’engage toutefois à retirer dès
          la première demande, tout contenu jugé illicite sous réserve d’avoir
          suffisamment d’éléments probants à cet effet. L’Utilisateur s’engage
          en outre, à respecter la clause 5 de propriété intellectuelle et à ne
          pas exploiter sans autorisation préalable et express, tout contenu du
          Site internet.
        </p>
      </Row>
      <Row className="my-5">
        <h5 className="bold my-2">
          Article 3 – Obligations spécifiques des Organisateurs
        </h5>
        <p className="py-2">
          3.1 Toute personne exerçant à titre professionnel ou privé une
          activité liée à l’organisation d’évènements publics ou privés, qu’elle
          que soit la structure associée, peut s’inscrire sous le profil
          spécifique dédié aux Organisateurs. A cet effet, il pourra créer ce
          profil (ci-après « le Profil Organisateur ») sur le Site internet, en
          fournissant les informations relatives à ses prestations et à son
          identité, dont il en garantit la véracité. Il aura la faculté d’en
          modifier le contenu à sa guise conformément aux présentes CG. Le
          contenu visible sur le Profil inclut notamment : des visuels, une
          description des services de l’Organisateur, des coordonnées générales.{" "}
          <br /> <br />
          3.2 Son Profil Organisateur sera créé par le biais d’une adresse email
          et d’un mot de passe. L’Organisateur s’engage à maintenir confidentiel
          ce mot de passe et ne pourra pas le partager avec des tiers. En cas
          d’utilisation fautive de son mot de passe, il devra immédiatement le
          notifier à BOOK’NOW qui se chargera de lui transmettre de nouvelles
          informations de connexion. En cas d’oubli, l’Organisateur pourra
          directement solliciter un nouveau mot de passe en cliquant sur « mot
          de passe oublié ». <br /> <br />
          3.3 L’Organisateur s’engage à respecter les présentes CG et notamment
          l’article 2 relatif aux obligations imposées à tous les Utilisateurs.
          Il est entièrement responsable de tout contenu diffusé sur son Profil
          Organisateur et ne pourra pas engager la responsabilité de BOOK’NOW à
          cet effet. <br /> <br />
          3.4 BOOK’NOW se réserve la possibilité de refuser certains
          Organisateurs qui ne répondraient pas à ses attentes et au processus
          d’enregistrement, ou qui refuseraient de se conformer aux présentes
          CG.
          <br /> <br /> 3.5 La date de création du Profil Organisateur marquera
          le début de l’accord entre l’Organisateur et BOOK’NOW. L’Organisateur
          pourra contacter BOOK’NOW à tout moment pour l’informer des
          changements possibles de son contrat. <br /> <br />
          3.6 L’Organisateur autorise BOOK’NOW à utiliser, de manière
          non-exclusive et gratuite, ses logos, marques et contenus fournis sur
          son Profil Organisateur. Il autorise également BOOK’NOW à exploiter
          gratuitement ces logos, marques et contenus dans les différentes
          sections du Site internet et les réseaux sociaux correspondants, sous
          réserve que son nom soit toujours cité. Ces utilisations ne sont
          autorisées à BOOK’NOW qu’à des fins de communication ou de publicité
          pendant la période maximum de validité reconnue par la législation
          nationale. Il ne déclare expressément qu’aucun des contenus fournis à
          BOOK’NOW ne viole d’une quelconque manière que ce soit, des droits de
          propriété intellectuelle ou de toute sorte, appartenant à des tiers.
          La responsabilité de BOOK’NOW ne pourra pas être engagée sur ce point
          en cas de réclamation de la part d’un tiers ou d’un ayant-droit.
          <br /> <br /> 3.7 En cas de faillite, de redressement ou de
          liquidation judiciaire de l’Organisateur, ce dernier est tenu
          d’informer BOOK’NOW qui aura alors la possibilité de supprimer son
          Profil Organisateur.
          <br /> <br />
          3.8 En créant un Profil Organisateur, l’Organisateur reconnait et
          accepte de conclure un mandat de facturation avec BOOK’NOW. En vertu
          de ce mandat de facturation, l’Organisateur confie exclusivement à
          BOOK’NOW, l’établissement et l’émission de ses factures pour les
          missions réalisées par le biais du site internet, et à la collecte du
          règlement desdites factures pour son compte. Ces factures seront
          accessibles sur le compte de l’Organisateur. Les commissions prélevées
          par BOOK’NOW au titre de ses services sont les suivantes : <br />{" "}
          <br />- 12,5 % au titre de la mise en relation avec l’Artiste,
          incluant toutes les prestations administratives nécessaires à
          l’utilisation des services de l’Artiste, <br /> <br />- 20 % pour les
          services dits de conciergerie par lesquels BOOK’NOW propose sur
          demande, trois devis d’Artistes pouvant répondre aux souhaits de
          l’Organisateur. A ce service s’accompagne également les services liés
          aux démarches administratives en lien avec l’utilisation des services
          de l’Artiste sélectionné.
        </p>
      </Row>

      <Row className="my-5">
        <h5 className="bold my-2">
          Article 4 – Obligations spécifiques des Artistes
        </h5>
        <p className="py-2">
          4.1 L’Artiste pourra créer un profil spécifique (ci-après « le Profil
          Artiste ») sur le Site internet, en fournissant les informations
          relatives à ses prestations et à son identité, dont il en garantit la
          véracité. Il aura la faculté d’en modifier le contenu à sa guise
          conformément aux présentes CG. Le contenu visible sur le Profil inclut
          notamment : des visuels, une description des services de l’Artiste,
          des coordonnées générales et les avis mis en ligne par les
          Organisateurs.
          <br /> <br /> 4.2 Tout Utilisateur exploitant une activité artistique
          de spectacle vivant peut créer un Profil Artiste, quelle que soit son
          statut (intermittent du spectacle, auto-entrepreneur, association loi
          1901 ou société). Cette activité de spectacle vivant doit néanmoins
          être exercée à titre professionnel et constituer la ressource
          principale de l’Artiste. A cet effet, l’Artiste doit rapporter sa
          qualité d’artiste de spectacle vivant lors de la création du compte
          Profil Artiste, en produisant tout document comptable et/ou
          administratif le justifiant. <br /> <br />
          4.3 Son Profil Artiste sera créé par le biais d’une adresse email et
          d’un mot de passe. L’Artiste s’engage à maintenir confidentiel ce mot
          de passe et ne pourra pas le partager avec des tiers. En cas
          d’utilisation fautive de son mot de passe, il devra immédiatement le
          notifier à BOOK’NOW qui se chargera de lui transmettre de nouvelles
          informations de connexion. En cas d’oubli, l’Artiste pourra
          directement solliciter un nouveau mot de passe en cliquant sur « mot
          de passe oublié ». <br /> <br />
          4.4 L’Artiste s’engage à respecter les présentes CG et notamment
          l’article 2 relatif aux obligations imposées à tous les Utilisateurs.
          Il est entièrement responsable de tout contenu diffusé sur son Profil
          Artiste et ne pourra pas engager la responsabilité de BOOK’NOW à cet
          effet. <br /> <br />
          4.5 BOOK’NOW se réserve la possibilité de refuser certains Artistes
          qui ne répondraient pas à ses attentes et au processus
          d’enregistrement, ou qui refuseraient de se conformer aux présentes
          CG. A cet effet, la demande d’inscription de l’Artiste fera l’objet
          d’une pré-inscription qui devra en amont être validée par BOOK’NOW.
          L’Artiste sera notifié par email du fait que sa demande d’inscription
          a été acceptée. <br /> <br />
          4.6 La date de création du Profil Artiste marquera le début de
          l’accord entre l’Artiste et BOOK’NOW. L’Artiste pourra contacter
          BOOK’NOW à tout moment pour l’informer des changements possibles de
          son contrat. <br /> <br />
          4.7 Les Organisateurs ont la possibilité à la suite d’une Mission, de
          déposer des avis sur le Profil des Artistes afin de faire part de leur
          opinion sur les services proposés. Ces avis apparaissent sur le Profil
          de l’Artiste, avec des notes allant de 1 à 5, accompagnés d’un
          commentaire, d’une date de publication et d’un titre. Ils ne pourront
          être modifiés par BOOK’NOW qu’en cas d’erreur. L’Artiste s’engage à
          les accepter et notamment à ne pas mettre fin à la collaboration avec
          BOOK’NOW en fonction de ces avis, ni d’y répondre avec des termes
          inappropriés, injurieux ou dénigrants. Il reconnait que des avis
          pourront être conservés même s’ils lui sont défavorables, dès lors
          qu’ils répondent de manière objective aux présentes CG. BOOK’NOW
          s’engage en revanche à supprimer tout avis et/ou toute réponse de
          l’Artiste qui seraient considérés comme illicites et qui ne
          respecteraient pas les présentes CG, notamment s’ils sont rédigés dans
          des conditions déloyales, mensongères, injurieuses ou discriminatoires
          (liste non exhaustive). La responsabilité de BOOK’NOW ne pourra pas
          être engagée en raison de conséquences négatives subies par l’Artiste
          à la suite d’un avis laissé sur son Profil Artiste. <br /> <br />
          4.8 L’Artiste autorise BOOK’NOW à utiliser, de manière non-exclusive
          et gratuite, ses logos, marques et contenus de propriété
          intellectuelle fournis sur son Profil Artiste. Il autorise également
          BOOK’NOW à exploiter gratuitement ces logos, marques et contenus dans
          les différentes sections du Site internet et les réseaux sociaux
          correspondants, sous réserve que son nom soit toujours cité. Ces
          utilisations ne sont autorisées à BOOK’NOW qu’à des fins de
          communication, de publicité ou commerciales, pendant la période
          maximum de validité reconnue par la législation nationale. Il ne
          déclare expressément qu’aucun des contenus fournis à BOOK’NOW ne viole
          d’une quelconque manière que ce soit, des droits de propriété
          intellectuelle ou de toute sorte, appartenant à des tiers. La
          responsabilité de BOOK’NOW ne pourra pas être engagée sur ce point en
          cas de réclamation de la part d’un tiers ou d’un ayant-droit. <br />{" "}
          <br />
          4.9 En créant un Profil Artiste, l’Artiste reconnait et accepte de
          conclure un mandat de facturation avec BOOK’NOW. En vertu de ce mandat
          de facturation, l’Artiste confie exclusivement à BOOK’NOW,
          l’établissement et l’émission de ses factures pour les missions
          réalisées par le biais du site internet, et à la collecte du règlement
          desdites factures pour son compte. Ces factures seront accessibles sur
          le compte de l’Artiste. Il accepte que BOOK’NOW prélève les
          commissions suivantes : <br /> <br />- 5 % sur le chiffre d’affaires
          généré par mission réalisée pour un Organisateur, au regard de
          l’accompagnement correspondant aux services de formulation de devis,
          de tiers de confiance, de prise en charge des démarches
          administratives de l’Artiste, <br /> <br />- 17,5 % sur le chiffre
          d’affaires généré par mission réalisée pour un Organisateur, au regard
          de l’accompagnement administratif précité mais également des services
          dits de production. En vertu de ces services, BOOK’NOW met à
          disposition ses compétences pour produire et réaliser des
          vidéos/images de présentation de l’Artiste, visant à être diffusées
          par l’Artiste sur l’ensemble de ses supports de communication ainsi
          que sur les supports de communication de BOOK’NOW. <br /> <br />-
          L’Artiste bénéficie également de la possibilité de présenter les
          services de BOOK’NOW à des organisateurs externes, non-inscrits sur le
          Site Internet. Dans cette hypothèse, BOOK’NOW prélèvera une commission
          de 5% sur le chiffre d’affaires généré par l’Artiste, au regard de
          l’accompagnement réalisé pour effectuer les démarches administratives
          de déclaration liées au travail de l’Artiste. La commission de 12,5%
          appliquée aux Organisateurs en vertu de l’article 3.8 ne sera pas ici
          ajoutée.
        </p>
      </Row>
      <Row className="my-5">
        <h5 className="bold my-2">Article 5 – Propriété intellectuelle</h5>
        <p className="py-2">
          5.1 Les textes, designs, vidéos, photographies, images, bases de
          données, interfaces graphiques, logos, marques et autres éléments du
          Site internet et des autres applications offrant les services de
          BOOK’NOW, sont protégés par des droits de propriété intellectuelle et
          demeurent la propriété exclusive de cette dernière. Tout le contenu,
          quel qu’il soit, fourni par BOOK’NOW aux Utilisateurs reste également
          la propriété exclusive de BOOK’NOW. Ainsi toute reproduction,
          transmission, adaptation, traduction, modification, divulgation au
          public de tout ou partie de l’ensemble des contenus précités, réalisée
          sans l’autorisation préalable et expresse de BOOK’NOW est strictement
          interdite.
          <br /> <br /> 5.2 BOOK’NOW ne concède aucune cession de droits, ni
          licence d’exploitation aux Utilisateurs sur les droits de propriété
          intellectuelle qu’elle détient. <br /> <br />
          5.3 Dans l’hypothèse où un Utilisateur ou un tiers considère qu’une
          violation de ses droits a été commise en raison du contenu diffusé par
          BOOK’NOW et/ou par un Organisateur et/ou par un Artiste, il devra le
          notifier directement à BOOK’NOW en justifiant sa demande avec les
          mentions suivantes : des informations permettant de l’identifier,
          l’indication du contenu incriminé, la preuve de l’existence des droits
          invoqués et de leur validité, la déclaration expresse de la véracité
          de ces éléments. Cette réclamation doit être adressée par écrit à
          BOOK’NOW à l’adresse suivante : contact@book-now.fr <br /> <br />
          5.4 Dans le cadre de ses services dits de production offerts aux
          Artistes, BOOK’NOW reste propriétaire des droits de propriété
          intellectuelle en lien avec les vidéos/images produites et réalisées
          pour le compte des Artistes. Il autorise à ce titre, chaque Artiste
          concerné à exploiter librement le contenu ainsi produit et réalisé sur
          l’ensemble de ses supports de communication, auprès de tout public,
          dans le monde entier et pour toute la durée légale de protection des
          droits d’auteur. Cette utilisation n’est consentie que pour permettre
          à l’Artiste de promouvoir et communiquer sur son activité. Article 6 –
          Description des services et fonctionnement du site internet <br />{" "}
          <br />
          6.1 Les services comprennent notamment : - La possibilité pour
          l’Utilisateur de créer un Profil Organisateur ou Profil Artiste ; - La
          mise en relation entre les Organisateurs, les Artistes par le biais du
          Site internet. A cet effet, BOOK’NOW se charge de l’ensemble des
          formalités administratives nécessaires à l’utilisation des services de
          l’Artiste ; - Le service dit de conciergerie à destination des
          Organisateurs, par lequel BOOK’NOW sur demande de l’Organisateur, lui
          propose trois devis d’Artistes répondant à ses attentes en termes de
          style, de géolocalisation, de date et de budget ; - Le service dit de
          production à destination d’Artistes certifiés par BOOK’NOW, par lequel
          BOOK’NOW réalise et produit du contenu audiovisuel et photo pour
          permettre à l’Artiste de communiquer sur son activité ; - La mise à
          disposition de moyens de paiement destinés à sécuriser le déroulé
          d’une mission réalisée par l’Artiste pour l’Organisateur (ci-après «
          la Mission ») ; - L’établissement et l’émission des factures relatives
          aux Missions réalisées par l’Artiste pour l’Organisateur en vertu du
          mandat de facturation, - La publication d’avis laissés par les
          Organisateurs sur les Profils des Artistes à la fin de la Mission.{" "}
          <br /> <br />
          6.2 S’agissant de la simple mise en relation entre l’Organisateur et
          l’Artiste. L’Organisateur et l’Artiste doivent avoir créé un compte
          conforme aux présentes CG. La réservation d’une mission sollicitée par
          l’Organisateur est réalisée de la façon suivante :
          <br /> <br />- L’Organisateur adresse un message à l’Artiste et
          échange avec lui des modalités de la réservation OU l’Organisateur
          peut mettre en ligne un évènement auquel les artistes pourront
          candidater, <br /> <br />- L’Organisateur précise les modalités de la
          mission de manière détaillée,
          <br /> <br /> - L’Organisateur reçoit un devis et/ou des devis qu’il
          peut accepter ou non. Un devis est accessible en ligne, téléchargeable
          sous la forme PDF et est valable pendant une durée de 30 jours à
          compter de son émission. Une fois accepté, il ne peut plus être
          modifié. <br /> <br />- A ce devis, BOOK’NOW ajoute immédiatement une
          commission de 12,5 % ainsi que les cotisations sociales afférentes, le
          coût prélevé par le prestataire d’établissement de fiche de paie et la
          TVA applicable en vigueur.
          <br /> <br />- Le devis est accepté par l’Organisateur lorsqu’il aura
          été réglé dans sa totalité par le biais du Site Internet. Les fonds
          transmis seront conservés sous séquestre par BOOK’NOW jusqu’à ce que
          la fin de la mission lui soit notifiée par l’Artiste et par
          l’Organisateur. <br /> <br />- Dans l’hypothèse où la mission n’est
          pas réalisée par l’Artiste en raison d’une annulation, BOOK’NOW fera
          de son mieux pour le mettre en relation avec un autre Artiste. A
          défaut, l’Organisateur sera intégralement remboursé dans un délai de
          10 jour ouvré. <br /> <br />- Dans l’hypothèse où la mission serait
          annulée par l’Organisateur plus de 14 jours avant sa date de
          réalisation, l’Organisateur serait remboursé de 45 % de la somme
          versée. Au-delà de cette période, l’Organisateur ne percevra aucun
          remboursement. Si l’annulation intervient dans les 7 jours suivant la
          réservation, l’Organisateur sera intégralement remboursé. Ces
          conditions d’annulations peuvent être préalablement modifiées par
          accords express et conjoints de l’Organisateur et de l’Artiste.
          <br /> <br />
          6.3 S’agissant du service de conciergerie offert aux Organisateurs par
          BOOK’NOW. L’Organisateur et l’Artiste doivent avoir créé un compte
          conforme aux présentes CG. La réservation d’une mission sollicitée par
          l’Organisateur est réalisée de la manière suivante : <br /> <br />-
          L’Organisateur adresse un message à BOOK’NOW en lui informant d’une
          manière détaillée de son souhait en termes de date, géolocalisation,
          style et budget.
          <br /> <br /> - BOOK’NOW se charge d’identifier trois profils Artiste
          inscrits sur le Site internet, qui répondraient à cette demande.{" "}
          <br /> <br />- L’Organisateur reçoit trois devis qu’il peut accepter
          ou non. Ces devis sont accessibles en ligne, téléchargeables sous la
          forme PDF et valables pendant une durée de 30 jours à compter de leur
          émission. Dès lors qu’un devis est accepté, il ne peut plus être
          modifié. <br /> <br />- A ce devis, BOOK’NOW ajoute immédiatement une
          commission de 20 % ainsi que les cotisations sociales afférentes, le
          coût prélevé par le prestataire d’établissement de fiche de paie et la
          TVA applicable en vigueur. A cet égard, BOOK’NOW fait appel à un
          prestataire d’établissement de fiche de paie externe, tel que
          Movinmotion. <br /> <br />- Un devis est accepté par l’Organisateur
          lorsqu’il aura été réglé dans sa totalité par le biais du Site
          internet. Les fonds transmis seront conservés sous séquestre par
          BOOK’NOW jusqu’à ce que la fin de la mission lui soit notifiée par
          l’Artiste et par l’Organisateur. <br /> <br />- Dans l’hypothèse où la
          mission n’est pas réalisée par l’Artiste en raison d’une annulation,
          l’Organisateur sera remboursé dans un délai de 10 jours.
          L’Organisateur et l’Artiste devront confirmer par écrit à BOOK’NOW
          qu’ils l’acceptent. <br /> <br />- Dans l’hypothèse où la mission
          serait annulée par l’Organisateur plus de 14 jours avant sa date de
          réalisation, l’Organisateur serait remboursé de 45 % de la somme
          versée. Au-delà de cette période, l’Organisateur ne percevra aucun
          remboursement. Si l’annulation intervient dans les 7 jours suivant la
          réservation, l’Organisateur sera intégralement remboursé. Ces
          conditions d’annulations peuvent être préalablement modifiées par
          accords express et conjoints de l’Organisateur et de l’Artiste. <br />{" "}
          <br />- Dans l’hypothèse où aucun des trois Artistes proposés par
          BOOK’NOW ne conviendrait à l’Organisateur, l’Organisateur resterait
          libre d’entrer directement en relation avec un autre Artiste figurant
          sur le Site Internet, selon le simple service de mise en relation.
          <br /> <br />
          6.4 S’agissant du service de production offert aux Artistes par
          BOOK’NOW. L’Artiste doivent avoir créé un compte conforme aux
          présentes CG et bénéficier d’une accréditation en qualité d’Artiste
          certifié par BOOK’NOW. Si l’Artiste le souhaite, il peut faire appel à
          l’expertise de BOOK NOW pour réaliser et produire des supports de
          communication – vidéo et image – visant à promouvoir ses services. Un
          RDV est alors convenu entre BOOK’NOW et l’Artiste en extérieur ou en
          studio pour réaliser la prestation. Cette prestation est réalisée
          selon un accord conjoint entre l’Artiste et BOOK’NOW, selon un brief
          défini en amont. Au devis adressé par l’Artiste à un Organisateur,
          BOOK’NOW ajoutera immédiatement une commission de 17,5 % ainsi que les
          cotisations sociales afférentes, le coût prélevé par le prestataire
          d’établissement de fiche de paie et la TVA applicable en vigueur.
          BOOK’NOW conservera les rushs et images issues de ces services de
          productions, dont il reste l’entier propriétaire. <br /> <br />
          6.5 S’agissant du service d’accompagnement administratif offert aux
          organisateurs externes. Tout Artiste peut mettre en relation un
          organisateur externe avec BOOK’NOW pour que BOOK’NOW se charge des
          démarches administratives liées à l’utilisation des services de
          l’Artiste dans le cadre d’un évènement. A cet effet, l’organisateur
          externe recevra par email un lien lui permettant de régler la somme
          due à BOOK’NOW. Au devis adressé par l’Artiste à cet organisateur
          externe, BOOK’NOW ajoutera immédiatement une commission de 5 % ainsi
          que les cotisations sociales afférentes, le coût prélevé par le
          prestataire d’établissement de fiche de paie et la TVA applicable en
          vigueur. <br /> <br />
          6.6 Les paiements réalisés par l’Organisateur ou l’Artiste sont
          effectués par carte bancaire par le biais d’une plateforme de paiement
          externe, telle que Mangopay.
          <br /> <br />
          6.7 La communication entre les Artistes et les Organisateurs est
          assurée par leur boîte de réception mise à disposition sur le Site
          internet. Ils peuvent être informés des différents échanges par
          différents canaux tels que des notifications SMS, PUSH ou emails. Les
          Artistes et les Organisateurs conservent la faculté de se désabonner
          de ces notifications à tout moment et/ou de modifier les informations
          de contact avec lesquelles ils sont engagés. <br /> <br />
          6.8 La responsabilité de BOOK’NOW ne peut en aucun cas être engagée au
          regard du résultat et de la qualité des échanges ou des prestations
          entre les Artistes et les Organisateurs. <br /> <br />
          6.9 En cas de différend entre l’Artiste et l’Organisateur quant à la
          mission réalisée, ces derniers s’engagent à en informer BOOK’NOW. A
          compter de cette saisine, BOOK’NOW agira en qualité de médiateur pour
          une durée maximale de 30 jours en tentant de proposer une solution
          amiable (ci-après « la Période de Médiation »). L’Artiste et
          l’Organisateur s’engagent à faire leurs meilleurs efforts pour mettre
          un terme rapide à ce litige. Si aucune solution amiable n’est trouvée,
          BOOK’NOW met fin à la Période de Médiation et se retrouve dessaisie du
          différend. L’Artiste et l’Organisateur feront alors leur affaire
          personnelle du règlement du différend. BOOK’NOW restituera alors le
          montant de la somme réglée en amont par l’Organisateur ou le Client,
          déduction faite de frais de dossiers de 100 euros et de sa commission
          ajoutée au devis initialement émis par l’Artiste. Cette somme ne
          pourra être sollicitée par l’Organisateur qu’à l’issue de la Période
          de Médiation, par lettre recommandée avec accusé de réception adressé
          au siège social de BOOK’NOW.{" "}
        </p>
      </Row>
      <Row className="my-5">
        <h5 className="bold my-2">Article 7 – Exclusion de responsabilité</h5>
        <p className="py-2">
          {" "}
          7.1 BOOK’NOW s’engage à faire tout son possible pour que son service
          soit toujours accessible. Néanmoins, il est possible que ces accès
          soient suspendus ou interrompus pour des raisons informatiques ou
          autres. La responsabilité de BOOK’NOW ne pourra donc pas être engagée
          sur ce point et notamment au regard des dommages de toute nature, qui
          pourraient survenir en raison de l’indisponibilité des services
          qu’elle propose. <br /> <br />
          7.2 BOOK’NOW se réserve la possibilité de supprimer tout contenu
          illicite et/ou contraire aux présentes CG en cas de notification qui
          lui est faite, ou à sa discrétion. Elle n’est à cet effet pas tenu de
          surveiller l’ensemble du contenu diffusé par les Utilisa
          <br /> <br />
          teurs et sa responsabilité ne pourra pas être engagée sur ce point.{" "}
          <br /> <br />
          7.3 La responsabilité de BOOK’NOW ne pourra pas être engagée en cas
          d’un manque d’exactitude, d’actualité, d’exhaustivité liée aux
          informations fournies par les Utilisateurs et notamment les
          Organisateurs ou les Artistes. <br /> <br />
          7.4 Lorsque l’Utilisateur est invité à naviguer vers des sites
          internet tiers non exploités par BOOK’NOW notamment pour obtenir des
          informations complémentaires, BOOK’NOW ne pourra pas être tenue
          responsable du contenu et des services proposés par ces sites
          internet. <br /> <br />
          7.5 La responsabilité de BOOK’NOW ne pourra pas être engagée dans
          l’hypothèse où une Mission n’est pas réalisée dans de bonnes
          conditions entre l’Organisateur et l’Artiste. <br /> <br />
          7.6 La responsabilité de BOOK’NOW ne pourra pas être engagée dans
          l’hypothèse où les Organisateurs et Artistes ne réalisent pas
          suffisamment de missions et/ou n’ont pas suffisamment de demandes
          et/ou ne voient pas leurs devis acceptés.
        </p>
      </Row>
      <Row className="my-5">
        <h5 className="bold my-2">Article 8 – Dispositions diverses</h5>
        <p className="py-2">
          {" "}
          8.1 Si une clause diverse présente dans les CG devait être déclarée
          nulle ou réputée non-écrite, en totalité ou en partie, pour quelque
          raison que ce soit, cette annulation affecterait uniquement cette
          disposition ou la partie de cette disposition en cause. Le reste des
          CG resterait en vigueur. <br /> <br />
          8.2 Toutes demandes et communications exigées entre BOOK’NOW et les
          Utilisateurs doivent être réalisées par écrit, par email ou adresse
          postale. <br /> <br />
          8.3 Les présentes CG sont conclues pour une durée indéterminée à
          compter de leur acceptation par l’Utilisateur. BOOK’NOW pourra fermer
          de plein droit le compte d’un Artiste ou d’un Organisateur qui ne se
          conformerait pas aux présentes CG et ce, sans que ne soit due aucune
          indemnité à quelque titre que ce soit. Article 9 – Législation
          applicable 9.1 Les présentes CG et aux conditions du Site internet
          sont régies par la législation française. <br /> <br />
          9.2 En cas de difficultés liées à l’interprétation, au respect ou à
          l’exécution de ces dernières, BOOK’NOW et les Utilisateurs tenteront
          de parvenir à un accord par la voie amiable, notamment par le biais
          d’une médiation. Si aucun accord n’est trouvé, le litige sera soumis
          aux tribunaux de la ville de Paris.
        </p>
      </Row>
    </Container>
  );
};
const ConditionsGenerales = ({ show, setShow }) => {
  return (
    <Modal show={show} onHide={() => setShow(false)} size="xl">
      <Modal.Header closeButton>
        <h3>Conditions générales</h3>
      </Modal.Header>
      <Modal.Body>
        <ConditionsGeneralesContent />
      </Modal.Body>
    </Modal>
  );
};

export default ConditionsGenerales;
